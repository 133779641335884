import Payment from 'components/userComponent/PaymentModal';
import { createElement } from 'react';
import adminRoutes from 'routes/admin';
import agentRoutes from 'routes/agent';
import authRoutes from 'routes/auth';
import errorRoutes from 'routes/error';
import kybRoutes from 'routes/kyb';
import { AppRouteConfig } from 'types/navigation';
import AccountContainer from 'views/dashboard/AccountContainer';
import Beneficiaries from 'views/dashboard/Benefit';
import Dashboard from 'views/dashboard/Dashboard';
import VerifyKyc from 'views/dashboard/Kyc';
import Transaction from 'views/dashboard/Trans';

import {
  ACCOUNT_ROUTE,
  BASE_ROUTE,
  BENEFICIARIES_ROUTE,
  KYC_ROUTE,
  PAYMENT_ROUTE,
  TRANSACTION_ROUTE,
} from './path';

const baseRoute: AppRouteConfig[] = [
  {
    path: BASE_ROUTE,
    element: createElement(Dashboard),
    restricted: true,
  },
  {
    path: BENEFICIARIES_ROUTE,
    element: createElement(Beneficiaries),
    restricted: true,
  },
  {
    path: ACCOUNT_ROUTE,
    element: createElement(AccountContainer),
    restricted: true,
  },
  {
    path: TRANSACTION_ROUTE,
    element: createElement(Transaction),
    restricted: true,
  },
  {
    path: KYC_ROUTE,
    element: createElement(VerifyKyc),
    restricted: true,
  },
  {
    path: PAYMENT_ROUTE,
    element: createElement(Payment),
    restricted: true,
  },
];

export default [
  ...baseRoute,
  ...authRoutes,
  ...errorRoutes,
  ...kybRoutes,
  ...agentRoutes,
  ...adminRoutes,
];
