import { useEffect } from 'react';
import {
  Bar,
  BarChart,
  //   YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from 'recharts';
import { useGetStatsMutation } from 'services/admin';

const StatisticsGraph: React.FC = () => {
  const [getStats, { data }] = useGetStatsMutation();

  useEffect(() => {
    getStats({});
  }, [getStats]);

  const statistics = data?.data;
  if (!statistics) {
    return <p>No Statistics data available.</p>;
  }

  const graphData = [
    {
      name: 'Transactions',
      value: statistics.totalNumberOfTransactions,
    },
    {
      name: 'Transaction Volume',
      value: parseFloat(statistics.transactionVolume.total),
    },
    {
      name: 'Total Fees',
      value: parseFloat(statistics.totalFees.total),
    },
    {
      name: 'Total Clients',
      value: statistics.totalClients,
    },
    {
      name: 'Verified Clients',
      value: statistics.totalVerifiedClients,
    },
    {
      name: 'Clients with Transactions',
      value: parseInt(statistics.totalClientsWithTransactions.userCount, 10),
    },
  ];

  return (
    <div className="p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-xl font-bold mb-4">Statistics Overview</h2>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={graphData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          {/* <YAxis /> */}
          <Tooltip />
          <Legend />
          <Bar dataKey="value" fill="#1174ED" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default StatisticsGraph;
