/* eslint-disable @typescript-eslint/no-explicit-any */
import { Spin, Table, TableColumnsType } from 'antd';
import searchIcon from 'assets/search.svg';
import { useEffect, useState } from 'react';
import { useGetWalletsMutation } from 'services/admin';

const AgentWallet = () => {
  const [loading, setLoading] = useState(true);
  const [wallets, setWallets] = useState<any[]>([]);
  const [getWallets] = useGetWalletsMutation();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredWallets, setFilteredWallets] = useState<any[]>([]);

  useEffect(() => {
    const fetchWallets = async () => {
      try {
        const response = await getWallets({}).unwrap();
        setWallets(response.data);
        setFilteredWallets(response.data);
      } catch (error) {
        // console.error('Failed to fetch wallets:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchWallets();
  }, [getWallets]);

  useEffect(() => {
    if (searchTerm) {
      const filteredData = wallets.filter((wallet) =>
        wallet.user.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredWallets(filteredData);
    } else {
      setFilteredWallets(wallets);
    }
  }, [searchTerm, wallets]);

  const formatAmount = (amount: number) => {
    return amount ? new Intl.NumberFormat().format(amount) : '0';
  };

  const columns: TableColumnsType<any> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_: any, record: any) => record.user?.name || 'N/A',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: 'phone_number',
      render: (_: any, record: any) => record.user?.phone_number || 'N/A',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (_: any, record: any) => record.user?.email || 'N/A',
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      key: 'balance',
      render: (balance: number) => formatAmount(balance),
    },
    {
      title: 'Available',
      dataIndex: 'available',
      key: 'available',
      render: (available: number) => formatAmount(available),
    },
  ];

  return (
    <div className="flex flex-col gap-3 border-[0.5px] border-[#EAECF0] rounded-[10px]">
      <div className="flex justify-between border-b-[0.5px] border-b-[#EAECF0] pb-[15px] pt-[15px] px-[15px] w-[100%]">
        <div className="w-[20%]">
          <h1 className="font-[900] text-[#343434] text-[18px] lg:text-[24px] leading-[40px]">
            Users Wallets
          </h1>
        </div>
        <div className="lg:flex hidden gap-4 font-[700] text-[12.57px] leading-[18.86px] text-[#8894EC]">
          <button className="flex justify-center items-center border border-[#8894EC] rounded-[30px] h-[33px] py-[7px] px-[20px]">
            Your Accounts
          </button>
          <button className="flex justify-center items-center border border-[#8894EC] bg-[#8894EC33] rounded-[30px] h-[33px] py-[7px] px-[20px]">
            Recipients
          </button>
        </div>
        <div className="input md:flex items-center hidden">
          <img
            src={searchIcon}
            alt="search"
            className="relative left-[30px] lg:left-[30px] lg:-ml-[30px]"
          />
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search User by Name"
            className="bg-[#F5F5F5] pl-[35px] w-[300px] rounded-[10px] h-[40px] py-[16.21px]"
          />
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center items-center" style={{ minHeight: '150px' }}>
          <Spin size="large" />
        </div>
      ) : (
        <Table
          className="cursor-pointer"
          columns={columns}
          dataSource={filteredWallets}
          rowKey="id"
          pagination={false}
        />
      )}
    </div>
  );
};

export default AgentWallet;
