/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Input,
  Modal,
  notification,
  Popconfirm,
  Spin,
  Table,
  TableColumnsType,
} from 'antd';
import AdminSideBar from 'components/adminComponent/AdminSideBar';
import KybDoc from 'components/adminComponent/KybDoc';
import { useEffect, useState } from 'react';
import { useGetKYBMutation, useRejectKYBMutation, useVerifyKYBMutation } from 'services/admin';

const GetKYB = () => {
  const [getKYB, { isLoading }] = useGetKYBMutation();
  const [verifyKYB] = useVerifyKYBMutation();
  const [rejectKYB] = useRejectKYBMutation();
  const [kybData, setKybData] = useState<any[]>([]);
  const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [rejectReason, setRejectReason] = useState('');

  const [isKybDocOpen, setIsKybDocOpen] = useState(false);
  const [selectedKybId, setSelectedKybId] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getKYB({}).unwrap();
        setKybData(response.data || []);
      } catch (error) {
        // message.error('Failed to fetch KYB data');
      }
    };
    fetchData();
  }, [getKYB]);

  const handleVerify = async (id: string) => {
    try {
      await verifyKYB({ id }).unwrap();
      notification.success({
        message: 'KYB Verify',
        description: 'KYB verified successfully',
      });
      setKybData((prev) => prev.filter((item) => item.id !== id));
    } catch (error: any) {
      notification.error({
        message: 'Failed to verify KYB',
        description: error?.data?.message || error?.data?.errors,
      });
    }
  };

  const handleReject = async () => {
    if (!selectedId) return;
    try {
      await rejectKYB({ id: selectedId, reason: rejectReason }).unwrap();
      notification.success({
        message: 'KYB Rejected',
        description: 'KYB rejected successfully',
      });
      setKybData((prev) => prev.filter((item) => item.id !== selectedId));
      setIsRejectModalVisible(false);
      setRejectReason('');
    } catch (error: any) {
      notification.error({
        message: 'Failed to reject KYB',
        description: error?.data?.message || error?.data?.errors,
      });
    }
  };

  const handleRowClick = (record: any) => {
    setSelectedKybId(record.id);
    setIsKybDocOpen(true);
  };

  const columns: TableColumnsType<any> = [
    {
      title: 'Name',
      dataIndex: ['business', 'owner', 'name'],
      key: 'owner_name',
    },
    {
      title: 'Business Name',
      dataIndex: ['business', 'name'],
      key: 'business_name',
    },
    {
      title: 'Business ID',
      dataIndex: ['business', 'business_identification_number'],
      key: 'business_identification_number',
    },
    {
      title: 'Director',
      dataIndex: ['business', 'director'],
      key: 'director',
    },
    {
      title: 'Country',
      dataIndex: ['business', 'country'],
      key: 'country',
    },
    {
      title: 'Actions',
      dataIndex: 'update',
      render: (_, record) => (
        <div className="flex gap-3">
          <Popconfirm
            title="Verify KYB?"
            onConfirm={(e) => {
              e?.stopPropagation();
              handleVerify(record.id);
            }}
            onCancel={(e) => e?.stopPropagation()}
            okText="Yes"
            cancelText="No"
            trigger="hover"
          >
            <button onClick={(e) => e.stopPropagation()} className="text-green-500">
              Verify
            </button>
          </Popconfirm>
          <button
            onClick={(e) => {
              e.stopPropagation();
              setSelectedId(record.id);
              setIsRejectModalVisible(true);
            }}
            className="text-red-500"
          >
            Reject
          </button>
        </div>
      ),
    },
  ];

  return (
    <AdminSideBar>
      <div className="flex flex-col gap-3 border-[0.5px] border-[#EAECF0] rounded-[10px]">
        <div className="flex justify-between border-b-[0.5px] border-b-[#EAECF0] pb-[15px] pt-[15px] px-[15px] w-[100%]">
          <div className="w-[20%]">
            <h1 className="font-[900] text-[#343434] text-[18px] lg:text-[24px] leading-[40px]">
              Users KYB
            </h1>
          </div>
        </div>

        {isLoading ? (
          <div className="flex justify-center items-center" style={{ minHeight: '150px' }}>
            <Spin size="large" />
          </div>
        ) : (
          <Table
            className="cursor-pointer"
            columns={columns}
            dataSource={kybData}
            rowKey="id"
            pagination={false}
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
            })}
          />
        )}
      </div>
      <Modal
        title="Reject KYB"
        open={isRejectModalVisible}
        onOk={handleReject}
        onCancel={() => setIsRejectModalVisible(false)}
      >
        <div className="flex flex-col gap-4">
          <Input.TextArea
            value={rejectReason}
            onChange={(e) => setRejectReason(e.target.value)}
            placeholder="Enter reason for rejection"
          />
          <div className="flex justify-end">
            <Button onClick={handleReject}>Reject KYB</Button>
          </div>
        </div>
      </Modal>
      <KybDoc
        isOpen={isKybDocOpen}
        handleCancel={() => setIsKybDocOpen(false)}
        kybId={selectedKybId}
      />
    </AdminSideBar>
  );
};

export default GetKYB;
