import AdminSideBar from 'components/adminComponent/AdminSideBar';
import AgentWallet from 'components/adminComponent/AgentWallet';

const GetWallets = () => {
  return (
    <AdminSideBar>
      <AgentWallet />
    </AdminSideBar>
  );
};

export default GetWallets;
