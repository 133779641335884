/* eslint-disable @typescript-eslint/no-explicit-any */
import { MenuFoldOutlined, PoweroffOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import logoIcon from 'assets/astrabiz-logo.png';
import userIcon from 'assets/avatar-cartoon.jpg';
import BusinessIcon from 'assets/Benefit';
import mobileLogo from 'assets/Favicon1.png';
import HomeIcon from 'assets/HomeIcon';
import AgentIcon from 'assets/Profile';
import TransIcon from 'assets/Transaction';
import AccountIcon from 'assets/Wallet';
import MobileSideBar from 'components/agentComponents/MobileSideBard';
import LogOutModal from 'components/LogOutModal';
import { FC, useEffect, useMemo, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useGetAdminMutation } from 'services/admin';

import 'styles/dashboard.css';

interface NavItemProps {
  to: string | string[];
  icon: React.ReactNode;
  label: string;
}
interface ErrorResponse {
  data: {
    message: string;
  };
}
interface APIErrorData {
  message: string;
  [key: string]: any;
}
interface CustomFetchBaseQueryError {
  status: number;
  data: APIErrorData;
}

const NavItem: FC<NavItemProps> = ({ to, icon, label }) => {
  const { pathname } = useLocation();
  const isActive = Array.isArray(to) ? to.includes(pathname) : pathname === to;

  return (
    <>
      {isActive ? (
        <div className="das text-[#1174ED] font-[700] flex justify-between items-center w-[100%]">
          <div className="flex items-center gap-[10px]">
            {icon}
            <p>{label}</p>
          </div>
          <div className="w-1 h-[36px] bg-[#3281FF] rounded-r-[25px]"></div>
        </div>
      ) : (
        <NavLink to={Array.isArray(to) ? to[0] : to}>
          <div className="flex items-center gap-[10px] cursor-pointer text-[#09090A] font-[400] text-[16px] leading-[22.4px] h-[36px]">
            {icon}
            <p>{label}</p>
          </div>
        </NavLink>
      )}
    </>
  );
};

interface AdminSideBarProps {
  children: React.ReactNode;
}

const AdminSideBar: FC<AdminSideBarProps> = ({ children }) => {
  const [getAdmin] = useGetAdminMutation();
  const [adminName, setAdminName] = useState('');
  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const showModal = () => {
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    const fetchAgent = async () => {
      try {
        const response = await getAdmin({});
        if ('data' in response) {
          setAdminName(response.data.data.name);
        } else if ('error' in response) {
          const error = response.error as CustomFetchBaseQueryError;
          if ('status' in error && error.status === 400) {
            const errorMessage =
              error.data?.message || error.data?.errors?.join(', ') || 'An error occurred';
            notification.info({
              message: 'Error',
              description: errorMessage,
            });
          }
        }
      } catch (error: unknown) {
        const err = error as ErrorResponse;
        if (
          err &&
          typeof err === 'object' &&
          'data' in err &&
          typeof err.data === 'object' &&
          'message' in err.data
        ) {
          notification.error({
            message: 'Error',
            description: err.data.message,
          });
        }
      }
    };
    fetchAgent();
  }, [getAdmin]);

  const sidebarItems = useMemo(
    () => [
      { to: '/admin/dashbord', icon: <HomeIcon />, label: 'Dashboard' },
      { to: '/admin/wallets', icon: <AccountIcon />, label: 'Wallet' },
      { to: '/admin/agents', icon: <AgentIcon />, label: 'Agents' },
      { to: '/admin/businesses', icon: <BusinessIcon />, label: 'Business' },
      { to: '/admin/kyb', icon: <TransIcon />, label: 'KYB' },
      { to: '/admin/transactions', icon: <TransIcon />, label: 'Transactions' },
    ],

    []
  );

  useEffect(() => {
    const selectedTab = sidebarItems.find((item) =>
      Array.isArray(item.to)
        ? item.to.includes(window.location.pathname)
        : item.to === window.location.pathname
    );

    if (selectedTab) {
      document.title = `AstraBiz | ${selectedTab.label}`;
    }
  }, [sidebarItems]);

  return (
    <div className="flex">
      <div className="lg:w-[20%] h-[100vh] hidden lg:flex flex-col gap-[20px] md:gap-[40px] xl:gap-[50px] pt-[40px] items-center bg-[#F5F9FF] sticky top-0 sha">
        <div>
          <img src={logoIcon} alt={logoIcon} className="hidden md:block" />
          <img
            src={mobileLogo}
            alt={mobileLogo}
            className="md:hidden block h-[30px] w-[30px] relative right-2 bottom-3"
          />
        </div>
        <div className="flex flex-col gap-4 items-cente lg:items-start text-[#09090A] text-[16px] not-italic font-[400] leading-[22.4px] w-[100%] pl-[10px] md:pl-[45px]">
          {sidebarItems.map((item, index) => (
            <NavItem key={index} to={item.to} icon={item.icon} label={item.label} />
          ))}
        </div>
      </div>
      <div className="lg:w-[80%] w-[100%]">
        <div className="flex justify-between lg:justify-end items-center bg-shadow bg-[#FFFFFF] py-[24px] px-[20px] md:px-[40px] h-[60px] md:h-[90px] xl:h-[100px] sticky top-0 z-10">
          <div className="cursor-pointer flex lg:hidden">
            <button onClick={showModal}>
              <MenuFoldOutlined />
            </button>
            <MobileSideBar isOpen={open} handleCancel={handleCancel} />
          </div>
          <div className="flex items-center gap-0 xl:gap-2">
            <div className="w-[52.04px] border border-[#F0F0F0] rotate-[90deg]"></div>
            <div className="flex items-center gap-4">
              <img
                src={userIcon}
                alt={userIcon}
                className="border-[2px] border-[#1174ED] rounded-[50%] h-[40px] w-[40px] md:h-[54px] md:w-[54px]"
              />
              <h1 className="font-[600] text-[10px] md:text-[16px] leading-[19.36px]">
                {adminName}
              </h1>
              <button onClick={openModal}>
                <PoweroffOutlined className="text-[#1174ED]" />
              </button>
            </div>
          </div>
        </div>
        <div className="bg-[url('/src/assets/bg.svg')] bg-[#fff] bg-no-repeat bg-cover bg-center py-[18px] xl:py-[24px] px-[20px] lg:px-[40px]">
          {children}
        </div>
      </div>
      <LogOutModal isOpen={isModalOpen} handleCancel={closeModal} userType="admin" />
    </div>
  );
};

export default AdminSideBar;
