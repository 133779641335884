import { Drawer, notification } from 'antd';
import rightIcon from 'assets/right _arrow.svg';
import { useFormik } from 'formik';
import { FC } from 'react';
import { useUpdatePersonaAccountMutation } from 'services/wallet';
import * as yup from 'yup';

interface UpdatePersonalAccountProps {
  isOpen: boolean;
  handleCancel: () => void;
}

const validationSchema = yup.object({
  bank_name: yup.string().required('Bank Name is required'),
  account_name: yup.string().required('Account Name is required'),
  account_number: yup
    .string()
    .matches(/^\d{10}$/, 'Account Number must be 10 digits')
    .required('Account Number is required'),
});

const UpdatePersonalAccount: FC<UpdatePersonalAccountProps> = ({ isOpen, handleCancel }) => {
  const [updatePersonaAccount, { isLoading }] = useUpdatePersonaAccountMutation();

  const formik = useFormik({
    initialValues: {
      bank_name: '',
      account_name: '',
      account_number: '',
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        await updatePersonaAccount(values).unwrap();
        notification.success({
          message: 'Request Successful',
          description: 'Request sent successfully',
        });
        resetForm();
        handleCancel();
      } catch (error) {
        // message.error(error?.data?.message || 'Something went wrong');
      }
    },
  });

  return (
    <Drawer
      closable
      destroyOnClose
      title={<p>Generate Personal Account</p>}
      open={isOpen}
      onClose={handleCancel}
      width={500}
    >
      <form className="flex flex-col gap-4" onSubmit={formik.handleSubmit}>
        <div className="flex items-center border border-[#D0D0D0] rounded-[12px] h-[54px] pr-4">
          <input
            type="text"
            name="bank_name"
            placeholder="Enter Bank Name"
            className="flex-1 px-[20px] focus:outline-none rounded-[12px] h-[54px]"
            value={formik.values.bank_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.bank_name && formik.errors.bank_name && (
            <p className="text-red-500 text-sm">{formik.errors.bank_name}</p>
          )}
        </div>

        <div className="flex items-center border border-[#D0D0D0] rounded-[12px] h-[54px] pr-4">
          <input
            type="text"
            name="account_name"
            placeholder="Enter Account Name"
            className="flex-1 px-[20px] focus:outline-none rounded-[12px] h-[54px]"
            value={formik.values.account_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.account_name && formik.errors.account_name && (
            <p className="text-red-500 text-sm">{formik.errors.account_name}</p>
          )}
        </div>

        <div className="flex items-center border border-[#D0D0D0] rounded-[12px] h-[54px] pr-4">
          <input
            type="text"
            name="account_number"
            placeholder="Enter Account Number"
            className="flex-1 px-[20px] focus:outline-none rounded-[12px] h-[54px]"
            value={formik.values.account_number}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.account_number && formik.errors.account_number && (
            <p className="text-red-500 text-sm">{formik.errors.account_number}</p>
          )}
        </div>

        <button
          type="submit"
          disabled={isLoading}
          className="flex justify-center items-center gap-2 text-[#F9F9FB] px-[20px] h-[40px] bg-[#1174ED] rounded-lg mt-4"
        >
          {isLoading ? 'Submitting...' : 'Send Request'}
          <img src={rightIcon} alt="right icon" />
        </button>
      </form>
    </Drawer>
  );
};

export default UpdatePersonalAccount;
