import { Button, Modal } from 'antd';
import DeletetModal from 'components/adminComponent/DeleteUserModal';
import FundUserModal from 'components/adminComponent/FundUser';
import GenerateAccount from 'components/adminComponent/GenerateAccount';
import { FC, useState } from 'react';

type User = {
  id: string;
  name: string;
  phone_number: string;
  is_email_verified: boolean;
  is_identity_verified: boolean;
  status: string;
};

interface InitialRequestModalProps {
  isOpen: boolean;
  handleCancel: () => void;
  selectedUser?: User | null;
  onDeleteSuccess: () => void;
}

const InitialRequestModal: FC<InitialRequestModalProps> = ({
  isOpen,
  handleCancel,
  selectedUser,
  onDeleteSuccess,
}) => {
  const [modalState, setModalState] = useState<{
    isDeleteModalOpen: boolean;
    isFundModalOpen: boolean;
    isGenerateModalOpen: boolean;
    selectedUser: User | null;
  }>({
    isDeleteModalOpen: false,
    isFundModalOpen: false,
    isGenerateModalOpen: false,
    selectedUser: selectedUser || null,
  });

  const handleOpenModal = (type: 'delete' | 'fund' | 'generate') => {
    setModalState((prevState) => ({
      ...prevState,
      isDeleteModalOpen: type === 'delete',
      isFundModalOpen: type === 'fund',
      isGenerateModalOpen: type === 'generate',
      selectedUser: selectedUser || null,
    }));
    handleCancel();
  };

  const handleCloseModal = (type: 'delete' | 'fund' | 'generate') => {
    setModalState((prevState) => ({
      ...prevState,
      isDeleteModalOpen: type === 'delete' ? false : prevState.isDeleteModalOpen,
      isFundModalOpen: type === 'fund' ? false : prevState.isFundModalOpen,
      isGenerateModalOpen: type === 'generate' ? false : prevState.isGenerateModalOpen,
    }));
  };

  return (
    <>
      <Modal open={isOpen} onCancel={handleCancel} footer={null}>
        <div className="flex flex-col gap-6 text-[15px]">
          <div className="flex flex-col gap-2">
            <h1 className="md:font-[700] font-medium">Confirm Request</h1>
            <p>Are you sure you want to [Fund], [GenerateAccount] or [Delete] this user?</p>
          </div>

          <div className="flex justify-end">
            <div className="flex gap-2 items-center">
              <Button onClick={() => handleOpenModal('fund')}>Fund</Button>
              <Button onClick={() => handleOpenModal('generate')}>Generate Account</Button>
              <Button onClick={() => handleOpenModal('delete')}>Delete</Button>
            </div>
          </div>
        </div>
      </Modal>

      <DeletetModal
        isOpen={modalState.isDeleteModalOpen}
        handleCancel={() => handleCloseModal('delete')}
        selectedUser={modalState.selectedUser}
        onDeleteSuccess={onDeleteSuccess}
      />

      <FundUserModal
        isOpen={modalState.isFundModalOpen}
        handleCancel={() => handleCloseModal('fund')}
        selectedUser={modalState.selectedUser}
      />

      <GenerateAccount
        isOpen={modalState.isGenerateModalOpen}
        handleCancel={() => handleCloseModal('generate')}
        selectedUser={modalState.selectedUser}
      />
    </>
  );
};

export default InitialRequestModal;
