import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { adminState } from 'types/admin';

const initialState: adminState = {
  admin: {},
};

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setBeneficiary: (state, { payload }: PayloadAction<adminState>) => {
      state.admin = payload;
    },
  },
});

export const walletSelector = (state: RootState) => state.admin;

export const { setBeneficiary } = adminSlice.actions;

export default adminSlice.reducer;
