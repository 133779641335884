/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Modal, notification } from 'antd';
import { FC } from 'react';
import { useApproveWithdrawalMutation } from 'services/admin';

interface ApproveWithdrawalModalProps {
  isOpen: boolean;
  onClose: () => void;
  transactionId: string;
}

const ApproveWithdrawalModal: FC<ApproveWithdrawalModalProps> = ({
  isOpen,
  onClose,
  transactionId,
}) => {
  const [approveWithdrawal] = useApproveWithdrawalMutation();

  const handleApprove = async () => {
    try {
      await approveWithdrawal({ id: transactionId }).unwrap();
      notification.success({
        message: 'Transaction Approved',
        description: 'The transaction has been approved successfully.',
      });
      onClose();
    } catch (error: unknown) {
      if (error && typeof error === 'object' && 'data' in error) {
        notification.error({
          message: 'Error Approving Transaction',
          description:
            (error as any).data.message || 'There was an error approving the transaction.',
        });
      } else {
        notification.error({
          message: 'Error Approving Transaction',
          description: 'There was an error approving the transaction.',
        });
      }
    }
  };

  return (
    <Modal open={isOpen} onCancel={onClose} footer={null}>
      <p>Are you sure you want to approve this transaction?</p>
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button type="primary" onClick={handleApprove}>
          Approve
        </Button>
      </div>
    </Modal>
  );
};

export default ApproveWithdrawalModal;
