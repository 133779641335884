import plusIcon from 'assets/Vector.svg';
import AddAgent from 'components/adminComponent/quickButtonModal/AddAgent';
import AddRate from 'components/adminComponent/quickButtonModal/AddRate';
import { useState } from 'react';

const ActionButton = () => {
  const [isAddAgentVisible, setIsAddAgentVisible] = useState(false);
  const [isAddRateVisible, setIsAddRateVisible] = useState(false);

  const handleModalVisibility = (modalName: string) => {
    switch (modalName) {
      case 'Add Agent':
        setIsAddAgentVisible(!isAddAgentVisible);
        break;
      case 'Add Rate':
        setIsAddRateVisible(!isAddRateVisible);
        break;
      default:
        break;
    }
  };

  const renderButton = (label: string, modalName: string) => (
    <button
      onClick={() => handleModalVisibility(modalName)}
      className="flex items-center gap-2 text-[#FFFFFF] bg-[#1174ED] py-[7px] px-[10px] md:px-[20px] rounded-[10px]"
    >
      {label}
      <img src={plusIcon} alt={plusIcon} />
    </button>
  );

  return (
    <div className="flex items-center gap-4">
      {renderButton('Add Agent', 'Add Agent')}
      {renderButton('Add Rate', 'Add Rate')}

      <AddAgent open={isAddAgentVisible} onClose={() => handleModalVisibility('Add Agent')} />
      <AddRate open={isAddRateVisible} onClose={() => handleModalVisibility('Add Rate')} />
    </div>
  );
};

export default ActionButton;
