import { BaseQueryApi, createApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  AssignTransactionInput,
  FundUserInput,
  GenerateAccountInput,
  OnboardAgentInput,
  PostRateInput,
  RejectKYBInput,
  UpdateUserInput,
} from 'types/admin';
import { baseQuery } from 'utils/baseQuery';

const customBaseQuery = fetchBaseQuery({
  baseUrl: baseQuery.baseUrl,
  prepareHeaders: (headers) => {
    return baseQuery.prepareHeaders(headers);
  },
});

const baseQueryWithReauth = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: object
) => {
  const result = await customBaseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    clearCookiesAndLogout();
  }

  return result;
};

const clearCookiesAndLogout = () => {
  document.cookie.split('; ').forEach((cookie) => {
    document.cookie = `${cookie.split('=')[0]}=;expires=${new Date(0).toUTCString()};path=/`;
  });

  window.location.href = '/admin/login';
};

export const adminApi = createApi({
  baseQuery: baseQueryWithReauth,
  reducerPath: 'adminApi',
  endpoints: (builder) => ({
    getPairs: builder.mutation({
      query: () => ({
        url: '/admin/rates',
        method: 'GET',
      }),
    }),
    getUsers: builder.mutation({
      query: () => ({
        url: '/admin/users',
        method: 'GET',
      }),
    }),
    getUpdateUsers: builder.mutation({
      query: (payload: UpdateUserInput) => ({
        url: `/admin/update-user-status/${payload.id}`,
        method: 'PATCH',
        body: payload,
      }),
    }),
    getAgent: builder.mutation({
      query: () => ({
        url: '/admin/agents',
        method: 'GET',
      }),
    }),
    getAdmin: builder.mutation({
      query: () => ({
        url: '/admin/auth/admin',
        method: 'GET',
      }),
    }),
    getTransaction: builder.mutation({
      query: () => ({
        url: '/admin/transactions',
        method: 'GET',
      }),
    }),
    approveTransaction: builder.mutation({
      query: (payload: UpdateUserInput) => ({
        url: `/admin/transactions/${payload.id}/approve`,
        method: 'PATCH',
        body: payload,
      }),
    }),
    approveWithdrawal: builder.mutation({
      query: (payload: UpdateUserInput) => ({
        url: `/admin/approve-withdrawal/${payload.id}`,
        method: 'PATCH',
        body: payload,
      }),
    }),
    assignTransaction: builder.mutation({
      query: (payload: AssignTransactionInput) => ({
        url: '/admin/assign-transaction-to-agent',
        method: 'POST',
        body: payload,
      }),
    }),
    generateAccount: builder.mutation({
      query: (payload: GenerateAccountInput) => ({
        url: '/admin/generate-account-for-user',
        method: 'POST',
        body: payload,
      }),
    }),
    getBVN: builder.mutation({
      query: (payload: UpdateUserInput) => ({
        url: `/admin/user-has-bvn/${payload}`,
        method: 'GET',
        body: payload,
      }),
    }),
    postRate: builder.mutation({
      query: (payload: PostRateInput) => ({
        url: '/admin/rates',
        method: 'POST',
        body: payload,
      }),
    }),
    onboardAgent: builder.mutation({
      query: (payload: OnboardAgentInput) => ({
        url: '/admin/onboard-agent',
        method: 'POST',
        body: payload,
      }),
    }),
    fundUser: builder.mutation({
      query: (payload: FundUserInput) => ({
        url: '/admin/fund-user',
        method: 'POST',
        body: payload,
      }),
    }),
    deleteUsers: builder.mutation({
      query: (payload: UpdateUserInput) => ({
        url: `/admin/user/${payload.id}/delete`,
        method: 'DELETE',
        body: payload,
      }),
    }),
    getStats: builder.mutation({
      query: () => ({
        url: '/admin/stats',
        method: 'GET',
      }),
    }),
    getWallets: builder.mutation({
      query: () => ({
        url: '/admin/wallets',
        method: 'GET',
      }),
    }),
    getBusiness: builder.mutation({
      query: () => ({
        url: '/admin/businesses',
        method: 'GET',
      }),
    }),
    getKYB: builder.mutation({
      query: () => ({
        url: '/admin/kyb',
        method: 'GET',
      }),
    }),
    getKYBDoc: builder.mutation({
      query: ({ id }: { id: string }) => ({
        url: `/admin/kyb-docs/${id}`,
        method: 'GET',
      }),
    }),
    verifyKYB: builder.mutation({
      query: (payload: UpdateUserInput) => ({
        url: `/admin/verify-kyb/${payload.id}`,
        method: 'PATCH',
        body: payload,
      }),
    }),
    rejectKYB: builder.mutation({
      query: (payload: RejectKYBInput) => ({
        url: `/admin/reject-kyb/${payload.id}`,
        method: 'PATCH',
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetAdminMutation,
  useGetPairsMutation,
  usePostRateMutation,
  useGetTransactionMutation,
  useApproveWithdrawalMutation,
  useApproveTransactionMutation,
  useGetAgentMutation,
  useAssignTransactionMutation,
  useGenerateAccountMutation,
  useGetBVNMutation,
  useOnboardAgentMutation,
  useFundUserMutation,
  useGetUsersMutation,
  useGetUpdateUsersMutation,
  useDeleteUsersMutation,
  useGetStatsMutation,
  useGetWalletsMutation,
  useGetBusinessMutation,
  useGetKYBMutation,
  useGetKYBDocMutation,
  useRejectKYBMutation,
  useVerifyKYBMutation,
} = adminApi;
