import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { adminApi } from 'services/admin';
import { agentApi } from 'services/agentServices/agent';
import { authApi } from 'services/auth';
import { beneficiaryApi } from 'services/beneficiaries';
import { businessApi } from 'services/business';
import { kybApi } from 'services/kyb';
import { transactionApi } from 'services/Transaction';
import { walletApi } from 'services/wallet';

import rootReducer from './rootReducer';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(authApi.middleware)
      .concat(businessApi.middleware)
      .concat(kybApi.middleware)
      .concat(walletApi.middleware)
      .concat(beneficiaryApi.middleware)
      .concat(transactionApi.middleware)
      .concat(agentApi.middleware)
      .concat(adminApi.middleware),
  devTools: import.meta.env.NODE_ENV !== 'production',
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
