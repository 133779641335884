import { createElement } from 'react';
import {
  ADMIN_LOGIN_ROUTE,
  AGENT_FORGOT_PASSWORD_ROUTE,
  AGENT_LOGIN_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  LOGIN_ROUTE,
  REGISTER_ROUTE,
  REGISTER_SUCCESS_ROUTE,
} from 'routes/path';
import { AppRouteConfig } from 'types/navigation';
import AdminLogin from 'views/auth/AdminLogin';
import AgentForgotPassword from 'views/auth/AgentForgotPassword';
import AgentLogin from 'views/auth/AgentsLogin';
import ForgotPassword from 'views/auth/ForgotPassword';
import Login from 'views/auth/Login';
import Register from 'views/auth/Register';
import RegisterSuccess from 'views/auth/RegisterSuccess';

const authRoutes: AppRouteConfig[] = [
  {
    path: REGISTER_ROUTE,
    element: createElement(Register),
    restricted: false,
  },
  {
    path: REGISTER_SUCCESS_ROUTE,
    element: createElement(RegisterSuccess),
    restricted: false,
  },
  {
    path: LOGIN_ROUTE,
    element: createElement(Login),
    restricted: false,
  },
  {
    path: FORGOT_PASSWORD_ROUTE,
    element: createElement(ForgotPassword),
    restricted: false,
  },
  {
    path: AGENT_LOGIN_ROUTE,
    element: createElement(AgentLogin),
    restricted: false,
  },
  {
    path: ADMIN_LOGIN_ROUTE,
    element: createElement(AdminLogin),
    restricted: false,
  },
  {
    path: AGENT_FORGOT_PASSWORD_ROUTE,
    element: createElement(AgentForgotPassword),
    restricted: false,
  },
];

export default authRoutes;
