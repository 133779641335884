import { combineReducers } from '@reduxjs/toolkit';
import { adminApi } from 'services/admin';
import { agentApi } from 'services/agentServices/agent';
import { authApi } from 'services/auth';
import { beneficiaryApi } from 'services/beneficiaries';
import { businessApi } from 'services/business';
import { kybApi } from 'services/kyb';
import { kycApi } from 'services/kyc';
import { transactionApi } from 'services/Transaction';
import { walletApi } from 'services/wallet';
import adminReducer from 'store/admin';
import agentReducer from 'store/agent';
import authReducer from 'store/auth';
import beneficiaryReducer from 'store/beneficiaries';
import businessReducer from 'store/business';
import kybSliceReducer from 'store/kyb';
import kycApiReducer from 'store/kyc';
import layoutReducer from 'store/layout';
import transactionReducer from 'store/Transaction';
import walletReducer from 'store/wallet';

const rootReducer = combineReducers({
  auth: authReducer,
  layout: layoutReducer,
  kyc: kycApiReducer,
  kyb: kybSliceReducer,
  business: businessReducer,
  wallet: walletReducer,
  beneficiary: beneficiaryReducer,
  transaction: transactionReducer,
  agent: agentReducer,
  admin: adminReducer,

  [authApi.reducerPath]: authApi.reducer,
  [kycApi.reducerPath]: kycApi.reducer,
  [kybApi.reducerPath]: kybApi.reducer,
  [businessApi.reducerPath]: businessApi.reducer,
  [walletApi.reducerPath]: walletApi.reducer,
  [beneficiaryApi.reducerPath]: beneficiaryApi.reducer,
  [transactionApi.reducerPath]: transactionApi.reducer,
  [agentApi.reducerPath]: agentApi.reducer,
  [adminApi.reducerPath]: adminApi.reducer,
});

export default rootReducer;
