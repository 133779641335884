/* eslint-disable @typescript-eslint/no-explicit-any */
import copyIcon from 'assets/copy-svgrepo-com.svg';
import ngnIcon from 'assets/nga.svg';
import UpdatePersonalAccount from 'components/userComponent/bank/UpdatePersonalAccountModal';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { useEffect, useState } from 'react';
import { useGetNairaWalletMutation, useGetPersonalAccountMutation } from 'services/wallet';

interface AccountSectionProps {
  title: string;
  data: Record<string, any> | null;
}

const BankDetails = () => {
  const [getNairaWallet] = useGetNairaWalletMutation();
  const [getPersonalAccount] = useGetPersonalAccountMutation();
  const [accountData, setAccountData] = useState<Record<string, any> | null>(null);
  const [personalAccountData, setPersonalAccountData] = useState<Record<string, any> | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchAccountDetails = async (
    fetchFunction: (params: object) => { unwrap: () => Promise<{ data: Record<string, any> }> },
    setState: (state: Record<string, any> | null) => void,
    fields: Record<string, any>
  ) => {
    try {
      const response = await fetchFunction({}).unwrap();
      setState(
        Object.fromEntries(
          Object.entries(fields).map(([key, fallback]) => [key, response?.data?.[key] || fallback])
        )
      );
    } catch {
      setState(null);
    }
  };

  useEffect(() => {
    fetchAccountDetails(getPersonalAccount, setPersonalAccountData, {
      account_name: 'N/A',
      bank_name: 'N/A',
      account_number: 'N/A',
    });
    const intervalId = setInterval(
      () =>
        fetchAccountDetails(getPersonalAccount, setPersonalAccountData, {
          account_name: 'N/A',
          bank_name: 'N/A',
          account_number: 'N/A',
        }),
      3000
    );
    return () => clearInterval(intervalId);
  }, [getPersonalAccount]);

  useEffect(() => {
    fetchAccountDetails(getNairaWallet, setAccountData, {
      account_name: 'N/A',
      account_number: 'N/A',
      bank_name: 'Safe Haven Microfinance Bank',
    });
  }, [getNairaWallet]);

  const AccountSection: React.FC<AccountSectionProps> = ({ title, data }: AccountSectionProps) => (
    <div className="border border-[#EAECF0] rounded-[8px] p-[15px] flex flex-col gap-3">
      <h2 className="font-[700] text-[16px] text-[#101828]">{title}</h2>
      <div className="flex justify-between flex-col md:flex-row gap-3">
        <div className="flex flex-col gap-2 w-full md:w-[70%]">
          <p className="text-[#A7A7A7] font-[500] text-[14px]">Account Name</p>
          <h1 className="text-[#101828] font-[700] text-[14px] leading-[20px]">
            {data?.account_name}
          </h1>
        </div>
        <div className="flex flex-col gap-2 w-full md:w-[30%]">
          <p className="text-[#A7A7A7] font-[500] text-[14px]">Account Number</p>
          <div className="flex items-center gap-2">
            <h1 className="text-[#101828] font-[700] text-[14px] leading-[20px]">
              {data?.account_number}
            </h1>
            <button onClick={useCopyToClipboard(data?.account_number)}>
              <img src={copyIcon} alt="Copy" />
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <p className="text-[#A7A7A7] font-[500] text-[14px]">Bank Name</p>
        <h1 className="text-[#101828] font-[700] text-[14px] leading-[20px]">{data?.bank_name}</h1>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col gap-5 lg:h-auto border-[0.5px] border-[#EAECF0] rounded-[10px] p-[15px] w-full">
      <h1 className="font-[900] text-[18px] lg:text-[24px] text-[#343434] leading-[40px]">
        Bank Details
      </h1>
      <AccountSection title="Business Account" data={accountData} />
      {personalAccountData ? (
        <AccountSection title="Personal Account" data={personalAccountData} />
      ) : (
        <div className="flex lg:flex-row flex-col justify-between items-center gap-[20px] lg:gap-[30px] md:gap-0 w-full border border-[#EAECF0] rounded-[8px] p-[15px]">
          <div className="flex items-center gap-2 lg:w-[60%]">
            <img src={ngnIcon} alt="ngn" width={42} height={42} />
            <div className="flex flex-col gap-1 w-[219px]">
              <h1 className="text-[#343434] font-bold leading-[18px]">Personal Account Request</h1>
              <p className="text-[12px] leading-[16.2px] font-medium text-[#666666]">
                Please click the 'Get Account' button to activate your Personal account.
              </p>
            </div>
          </div>
          <button
            onClick={() => setIsModalOpen(true)}
            className="flex items-center gap-2 text-[#fff] px-[20px] text-nowrap h-[40px] bg-[#1174ED] rounded-lg"
          >
            Update Account
          </button>
          <UpdatePersonalAccount isOpen={isModalOpen} handleCancel={() => setIsModalOpen(false)} />
        </div>
      )}
    </div>
  );
};

export default BankDetails;
