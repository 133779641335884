import ActionButton from 'components/adminComponent/ActionButton';
import AdminSideBar from 'components/adminComponent/AdminSideBar';
import Pairs from 'components/adminComponent/Pairs';
import StatisticsGraph from 'components/adminComponent/Statistics';
import UserTable from 'components/adminComponent/UserTable';

const AdminDashboard = () => {
  return (
    <AdminSideBar>
      <div className="flex flex-col gap-[60px]">
        <ActionButton />

        <Pairs />
        <StatisticsGraph />
        <UserTable />
      </div>
    </AdminSideBar>
  );
};

export default AdminDashboard;
